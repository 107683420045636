<template>
  <b-card>
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <div class="d-flex">
          <feather-icon icon="TabletIcon" size="20"/>
          <h4 class="mb-0 mt-0 ml-50">Form Information</h4>
        </div>
      </b-col>
      <b-col cols="12" md="6" lg="6" class="pt-1">
        <b-card no-body class="border pl-1 pt-1 mb-1">
          <table class="mt-2 mt-xl-0 w-100">
            <tr class="border-bottom">
              <th class="pb-50"><strong>Date</strong></th>
              <td class="pb-50">
                <date-time-column-table :data="dataInfo.created_date"></date-time-column-table>
              </td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><strong>First Name</strong></th>
              <td class="py-50">{{ dataInfo.first_name }}</td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><strong>Last Name</strong></th>
              <td class="py-50">{{ dataInfo.last_name }}</td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><strong>Phone</strong></th>
              <td class="py-50">{{ dataInfo.phone }}</td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><strong>Email</strong></th>
              <td class="py-50">{{ dataInfo.email }}</td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><strong>State</strong></th>
              <td class="py-50">{{ dataInfo.state }}</td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><strong>Address</strong></th>
              <td class="py-50">{{ dataInfo.address }}</td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><strong>Recommended By</strong></th>
              <td class="py-50">{{ dataInfo.recommended_by }}</td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><strong>Consultation Service</strong></th>
              <td class="py-50">{{ dataInfo.consultation_service }}</td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><strong>Service Type</strong></th>
              <td class="py-50">{{ dataInfo.service_type }}</td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><strong>Design Type</strong></th>
              <td class="py-50">{{ dataInfo.design_type }}</td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><strong>Wish List Other</strong></th>
              <td class="py-50">{{ dataInfo.wish_list_other }}</td>
            </tr>
            <tr class="border-top">
              <th class="py-50"><strong>Project Start</strong></th>
              <td class="py-50">{{ dataInfo.project_start }}</td>
            </tr>
            <tr class="border-top">
              <th class="py-50"><strong>Recommended By Other</strong></th>
              <td class="py-50">{{ dataInfo.recommended_by_other }}</td>
            </tr>
          </table>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" lg="6" class="pt-1">
        <b-card no-body class="border pl-1 pt-1 mb-1">
          <table class="mt-2 mt-xl-0 w-100">
            <tr class="border-top">
              <th class="py-50"><strong>Project Budget</strong></th>
              <td class="py-50">{{ dataInfo.project_budget }}</td>
            </tr>
            <tr class="border-top">
              <th class="py-50"><strong>Basement Status</strong></th>
              <td class="py-50">{{ dataInfo.basement_status }}</td>
            </tr>
            <tr class="border-top">
              <th class="py-50"><strong>Home Style</strong></th>
              <td class="py-50">{{ dataInfo.home_style }}</td>
            </tr>
            <tr class="border-top">
              <th class="py-50"><strong>Home Style Other</strong></th>
              <td class="py-50">{{ dataInfo.home_style_other }}</td>
            </tr>
            <tr class="border-top">
              <th class="py-50"><strong>Home Age</strong></th>
              <td class="py-50">{{ dataInfo.home_age }}</td>
            </tr>
            <tr class="border-top">
              <th class="py-50"><strong>Home Sqft</strong></th>
              <td class="py-50">{{ dataInfo.home_sqft }}</td>
            </tr>
            <tr class="border-top">
              <th class="py-50"><strong>Basement Sqft</strong></th>
              <td class="py-50">{{ dataInfo.basement_sqft }}</td>
            </tr>
            <tr class="border-top">
              <th class="py-50"><strong>Hoa</strong></th>
              <td class="py-50">{{ dataInfo.hoa }}</td>
            </tr>
            <tr class="border-top">
              <th class="py-50"><strong>Water Service</strong></th>
              <td class="py-50">{{ dataInfo.water_service }}</td>
            </tr>
            <tr class="border-top">
              <th class="py-50"><strong>Sewer Service</strong></th>
              <td class="py-50">{{ dataInfo.sewer_service }}</td>
            </tr>
            <tr class="border-top">
              <th class="py-50"><strong>Gas Service</strong></th>
              <td class="py-50">{{ dataInfo.gas_service }}</td>
            </tr>
            <tr class="border-top">
              <th class="py-50"><strong>Second Egress</strong></th>
              <td class="py-50">{{ dataInfo.second_egress }}</td>
            </tr>
            <tr class="border-top">
              <th class="py-50"><strong>Referral Name</strong></th>
              <td class="py-50">{{ dataInfo.referral_name }}</td>
            </tr>
          </table>
        </b-card>
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <div class="d-flex pb-1">
          <feather-icon icon="TabletIcon" size="20"/>
          <h4 class="mb-0 mt-0 ml-50">Selected Services</h4>
        </div>
        <b-card no-body class="border pl-1 pt-1 mb-1">
          <table class="mt-2 mt-xl-0 w-100">
            <tr class="border-bottom" v-for="data in dataInfo.selected_services_list" :key="data.id">
              <td class="pb-50">{{ data.description }}</td>
            </tr>
          </table>
        </b-card>
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <div class="d-flex pb-1">
          <feather-icon icon="TabletIcon" size="20"/>
          <h4 class="mb-0 mt-0 ml-50">Design Services</h4>
        </div>
        <b-card no-body class="border pl-1 pt-1 mb-1">
          <table class="mt-2 mt-xl-0 w-100">
            <tr class="border-bottom" v-for="data in dataInfo.design_services" :key="data.id">
              <td class="pb-50">{{ data.description }}</td>
            </tr>
          </table>
        </b-card>
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <div class="d-flex pb-1">
          <feather-icon icon="TabletIcon" size="20"/>
          <h4 class="mb-0 mt-0 ml-50">Wish List</h4>
        </div>
        <b-card no-body class="border pl-1 pt-1 mb-1">
          <table class="mt-2 mt-xl-0 w-100">
            <tr class="border-bottom" v-for="data in dataInfo.wish_list" :key="data.id">
              <td class="pb-50">{{ data.description }}</td>
            </tr>
          </table>
        </b-card>
      </b-col>
    </b-row>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BAvatar, BTab,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/cms/forms/store"
import router from '@/router'
import Overlay from "@/components/Overlay.vue"
import ActionButtons from "@/components/Form/ActionButtons.vue"
import {useToast} from 'vue-toastification/composition'
import {avatarText, getApiFile, statusOptions, yesNoOptions} from "@core/utils/filter"
import {onUnmounted, ref} from '@vue/composition-api'
import {toastMessage} from "@core/utils/utils"
import DateTimeColumnTable from "@/components/Table/DateTimeColumnTable.vue";

export default {
  components: {
    BTab,
    BAvatar,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,

    DateTimeColumnTable,
    vSelect,
    ActionButtons,
    Overlay,

    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)

    const dataInfo = ref({})

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id > 0 ? router.currentRoute.params.id : 0}).then(response => {
        dataInfo.value = response.data.data
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'cms-forms-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    return {
      busy,
      dataInfo,

      statusOptions,
      yesNoOptions,

      getApiFile,
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>